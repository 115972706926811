// @ts-nocheck
import { css } from 'styled-components';

export const breakpoints: BreakpointObject = {
    xs: '0',
    sm: '768px',
    md: '992px',
    lg: '1328px',
    xl: '1920px'
};

export interface BreakpointObject {
    xs?: string;
    sm?: string;
    md?: string;
    lg?: string;
    xl?: string;
}

export const breakpoint: any = Object.keys(breakpoints).reduce((accumulator, label) => {
    accumulator[label] = (...args: any) => css`
        @media (max-width: ${breakpoints[label]}) {
            ${css(...args)};
        }
    `;

    return accumulator;
}, {});
