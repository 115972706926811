import React, { useMemo, useEffect } from 'react';
// eslint-disable-next-line
import type { SpaProps } from '@nab/x-spa-react';
import { MiniAppInstance } from '@nab/x-spa-react';

import RouteError from './Error/RouteError';
import AppInitError from './Error/AppInitError';

import { AppViewport, SpaWrapper } from './common/styles';
import { safeGet } from '../utils/tools';

export function GlobalSpa({ context, activeAppInstance, initError, routeError, actions }: SpaProps) {
    useEffect(() => {
        actions.dispatchEvent("app_start");
        actions.authenticate();   
    }, [actions]);

    const isLoginCTX = safeGet(()=>(activeAppInstance.app as any).contextId) === "open-identity-miniapp"

    if (process.env.NODE_ENV === "development") {
        console.log("GlobalSpa rerender :: ", context, activeAppInstance);
    }

    return (
        <SpaWrapper className={isLoginCTX && "is-login"}>
            {useMemo(
                () => (
                    <AppViewport>
                        {initError ? <AppInitError app={activeAppInstance && activeAppInstance.app} error={initError} /> : ''}
                        {!initError && routeError ? <RouteError routeError={routeError} /> : ''}
                        {!initError && !routeError && activeAppInstance ? (
                            <MiniAppInstance errorSlot={AppInitError} appInstance={activeAppInstance} />
                        ) : (
                            ''
                        )}
                    </AppViewport>
                ),
                [activeAppInstance, initError, routeError]
            )}
        </SpaWrapper>
    );
}

export default GlobalSpa;
