import React from 'react';
import styled from 'styled-components';
import { H1, H3 as NUIH3, Link, Container } from '@nab/nui-react';
import { CrashedUFO } from './crashed-ufo';
import { ERROR_TYPE } from '../enum';
import { configMgr } from '@nab/nab-x-sdk-browser';
import { getOrigin } from './ErrorHelper';

interface Props {
    heading: string;
    subhead: string;
    errorType?: string;
    children?: any;
}

const StyledContainer = styled(Container)`
    text-align: center;
    padding: 50px 0;

    svg {
        max-width: 300px;
        margin: 25px 0;
    }
`;

const H3 = styled(NUIH3)`
    color: ${({ theme }) => theme.color.grey50};
    margin: 1rem 0;
`;

const StyledLink = styled(Link)`
    font-size: 1.5rem;
    vertical-align: unset;
`;

export const NotFound = ({ heading, subhead, errorType, children }: Props) => {
    const akamaiPrefix = configMgr.get('BUILD_ENVIRONMENT') === 'local' ? '' : 'openapi/external-developer-shell/latest/';

    return (
        <StyledContainer fluid={false} noSideGutters={false}>
            <H1 data-testid="heading">{heading}</H1>
            <CrashedUFO />
            <H3 variant="light">
                {subhead}
                {errorType === ERROR_TYPE.ERR_401 && (
                    <StyledLink href={`${getOrigin()}/${akamaiPrefix}login/identity`} color="primary">
                        Login Page
                    </StyledLink>
                )}
                .
            </H3>
            {children}
        </StyledContainer>
    );
};
