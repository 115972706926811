import { configMgr } from '@nab/nab-x-sdk-browser';
import { InMemoryCache } from 'apollo-cache-inmemory';
import ApolloClient from 'apollo-boost';

const getApolloClient = (auth: string) => {
    const cache = new InMemoryCache();

    const clientGatewayHost = configMgr.get('CLIENT_GW_HOST');

    const client = new ApolloClient({
        request: operation => {
            operation.setContext({
                headers: {
                    authorization: `Bearer ${auth}`
                }
            });
        },
        // For local dev, set CLIENT_GW_HOST in .env.local
        // NABX_EXTERNAL_DEVELOPER_SHELL_FRONTEND_CLIENT_GW_HOST=localhost:<<bff port>>
        // OR
        // NABX_EXTERNAL_DEVELOPER_SHELL_FRONTEND_CLIENT_GW_HOST=localhost:<<frontend port because we have proxy in package.json>>
        uri: `https://${clientGatewayHost}/v1/open/external-shell-miniapp-bff`,
        credentials: 'include',
        cache
    });

    return client;
};

export { getApolloClient };
