import * as React from 'react';

export const NOT_PROVIDED = 'NOT_PROVIDED';

export interface UserContextInterface {
    hasUsageAgreement: boolean;
    access: any;
    sub: string;
    setHasAgreedToUsageAgreement?: (hasUsageAgreement: boolean) => void;
}

export const UserContext = React.createContext<UserContextInterface | null>(null);

export const UserProvider: React.FC<any> = ({ userCtx, children }) => {
    const [user, setUser] = React.useState(userCtx);

    const setHasAgreedToUsageAgreement = (agree: boolean) => {
        return setUser({
            ...user,
            hasUsageAgreement: agree
        });
    };

    return (
        <UserContext.Provider
            value={{
                ...user,
                setHasAgreedToUsageAgreement
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export interface UserContextConsumerInterface {
    userContext: UserContextInterface;
}

export const withUserContext = WrappedComponent => ({ userCtx, ...props }) => {
    return (
        <UserProvider userCtx={userCtx}>
            <WrappedComponent {...props} />
        </UserProvider>
    );
};
