export enum ERROR_MESSAGE {
    DEFAULT = 'An error has occurred. Please email nabapi@nab.com.au for further investigation.',
    ERROR_RETURNED = 'Error returned:',
    RETRIEVING_USER_FAIL = 'Error retrieving User Details',
    UNAUTHORIZED = 'HTTP Status 401 - Unauthorized',
    UNAUTHORIZED_SUBHEADING = 'An error has occurred. Your session may have expired. Please return to the ',
}

export enum ERROR_TYPE {
    ERR_401 = '401',
    ERR_USER = 'ERR_USER'
}
