/* tslint:disable max-line-length */
import * as React from 'react';

export const CrashedUFO: React.FunctionComponent<any> = props => {
    return (
        <svg className="crashedufo-icon" id="crashed-ufo" x="0px" y="0px" viewBox="0 0 28 17" focusable="false">
            <polygon fill="#57B9E9" points="6.7,14.5 11.4,9.6 12.3,10.5 8.4,14.5 " />
            <polygon fill="#57B9E9" points="5.2,14.5 13,6.4 13,7.5 6.2,14.5 " />
            <path
                fill="#263751"
                d="M9.2,7.2l-3.8,3.9c0,0-0.7,1.3-0.7,3.3l7.9-8.2C12.7,6.2,11.2,6.1,9.2,7.2z M6.5,11.7c-0.2,0-0.4-0.2-0.4-0.4
                s0.2-0.4,0.4-0.4s0.4,0.2,0.4,0.4S6.7,11.7,6.5,11.7z M7.9,10.2c-0.2,0-0.4-0.2-0.4-0.4s0.2-0.4,0.4-0.4c0.2,0,0.4,0.2,0.4,0.4
                S8.1,10.2,7.9,10.2z M9.5,8.5c-0.2,0-0.4-0.2-0.4-0.4c0-0.2,0.2-0.4,0.4-0.4S9.8,8,9.8,8.2C9.8,8.4,9.7,8.5,9.5,8.5z"
            />
            <path fill="#263751" d="M2.8,14.5h1.3c0,0-0.1-1,0.3-2.4C4.4,12.1,3.1,13.1,2.8,14.5z" />
            <path fill="#57B9E9" d="M5.2,10.6l2.6-2.6c0,0-1.9-1.7-3.6,0.2C4.1,8.1,3.8,9.2,5.2,10.6z" />
            <path fill="#57B9E9" d="M4.8,10.9L4,11.7c0,0-1.4-1.3-0.3-2.9C3.7,8.8,3.8,10.1,4.8,10.9z" />
            <rect
                x="12.3"
                y="11.8"
                transform="matrix(0.6811 -0.7322 0.7322 0.6811 -5.2488 13.2649)"
                fill="#D8D8D8"
                width="0.6"
                height="1.8"
            />
            <rect
                x="11.2"
                y="12.8"
                transform="matrix(0.6811 -0.7322 0.7322 0.6811 -6.3753 12.822)"
                fill="#D8D8D8"
                width="0.6"
                height="1.8"
            />
            <polygon fill="#D8D8D8" points="10.7,14.5 9.8,14.5 9.7,14.4 10,13.9 " />
            <rect
                x="16.6"
                y="1"
                transform="matrix(0.9624 -0.2718 0.2718 0.9624 -0.4499 4.7225)"
                fill="#263751"
                width="0.5"
                height="5.9"
            />
            <rect
                x="22.7"
                y="3.3"
                transform="matrix(0.9296 0.3686 -0.3686 0.9296 3.56 -8.1045)"
                fill="#263751"
                width="0.5"
                height="3.9"
            />
            <rect x="19.6" y="3.6" fill="#263751" width="0.5" height="1.4" />
            <rect x="19.6" y="2.1" fill="#263751" width="0.5" height="0.5" />
            <rect
                x="25.5"
                y="8.2"
                transform="matrix(0.6288 0.7776 -0.7776 0.6288 16.8507 -16.5796)"
                fill="#263751"
                width="0.5"
                height="2.4"
            />
            <polygon
                fill="#914513"
                points="17.1,14.5 13.5,11 16.9,12.1 16,8 18.6,11.5 19.9,6.1 21.2,11.4 23.7,8 23.3,12.2 26.7,11.1 22.8,14.5
                "
            />
            <polygon
                fill="#D66316"
                points="17.3,13.9 22.6,13.9 24.1,12.5 22.6,13 22.9,10.1 21,12.7 19.9,8.7 18.9,12.7 17.2,10.7 17.7,12.9
                15.8,12.4 "
            />
            <path
                fill="#DDDEE1"
                d="M26.1,16.4H1.6c-0.3,0-0.6-0.3-0.6-0.6v0c0-0.3,0.3-0.6,0.6-0.6h24.4c0.3,0,0.6,0.3,0.6,0.6v0
                C26.7,16.1,26.4,16.4,26.1,16.4z"
            />
        </svg>
    );
};

CrashedUFO.displayName = 'CrashedUFO';
