import React from 'react';
import { GlobalStyle, Theme, Link, BackIcon } from '@nab/nui-react';
import LoginNavBar from '../../Navbar/LoginNavBar';
import Footer from '../../Footer/Footer';
import { ErrorComponent, IErrorType, getOrigin } from './ErrorHelper';
import styled from 'styled-components';
import { configMgr } from '@nab/nab-x-sdk-browser';

const Wrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0;
    padding: 0;
    transition: transform 0.3s, -webkit-transform 0.3s;
    @media (max-width: 640px) {
        min-height: 75vh;
    }

    @media (min-width: 640px) {
        min-height: 80vh;
    }

    @media (min-width: 800px) {
        min-height: 85vh;
    }
`;

export const UnauthorizedErrorPage: React.FC<IErrorType> = ({ errorType }: IErrorType) => {
    // deepcode ignore DeleteOfNonProperty: False positive, this is to delete bearerToken if exists
    delete sessionStorage?.bearerToken;
    const akamaiPrefix = configMgr.get('BUILD_ENVIRONMENT') === 'local' ? '' : 'openapi/external-developer-shell/latest/';

    return (
        <>
            <GlobalStyle normalize borderBox />
            <Theme>
                <Wrapper>
                    <div style={{ position: 'fixed', top: '0', width: '-webkit-fill-available', left: '0' }}>
                        <LoginNavBar role="banner" position={'initial'} />
                        <div style={{ paddingLeft: '24px', paddingTop: '16px' }}>
                            <Link href={`${getOrigin()}/${akamaiPrefix}login/identity`} icon={BackIcon} color="primary">
                                Login Page
                            </Link>
                        </div>
                    </div>
                    <ErrorComponent errorType={errorType} />
                </Wrapper>
                <div style={{ bottom: '0', position: 'fixed', width: '-webkit-fill-available' }}>
                    <Footer />
                </div>
            </Theme>
        </>
    );
};
