import React from 'react';
import styled from 'styled-components';
import { Icon, H5 } from '@nab/nui-react';
import { BackToTop } from './BackToTop';

export function Footer() {
    return (
        <FooterContainer data-testid="footer" aria-label="Footer" role="contentinfo" >
            <BackToTop />
            <StyledDisclaimer data-testid="legals">
                <H5>© National Australia Bank Limited</H5> 
                ABN 12 004 044 937 AFSL and Australian Credit Licence 230686 (NAB). Use of information on this page is
                governed by Australian Law and is subject to the{" "}
                <a href="https://www.nab.com.au/common/website-disclaimer">disclaimers</a>
                . Please also read our{" "}
                <a href="https://www.nab.com.au/common/privacy-policy">Privacy Policy.</a>
                <SytledIcon name="LinkIcon" size="sm"/>

            </StyledDisclaimer>
        </FooterContainer>
    );
}

export default Footer;

export const FooterContainer = styled.div`
    background-color: ${({ theme }) => theme.color.black};
    padding: 2rem 0 2rem;
    bottom: 0;
    color: ${({ theme }) => theme.color.white};
    text-align: left;
    > div {
        margin: 0 2rem;
    }
`;

export const StyledDisclaimer = styled.div`
    text-align: left;
    padding-left: 2rem;
    font-size: 14px;
    max-width: 750px;
    line-height: 1.4;

    H5{
        color: white;
        font-size: 14px;
    }

    a{
        color:white;
    }
`
export const SytledIcon = styled(Icon)`
    margin-bottom: -8px;
`
