import React, { MouseEvent, useEffect, useState } from 'react';
import { AppActions } from '@nab/nab-x-react';
import { Breadcrumbs, Link } from '@nab/nui-react';
import { SpaContextMenuProps } from '@nab/x-spa-react';
import styled from 'styled-components';
import { safeGet, trimLeadingSlash } from '../../utils/tools';
import { breadCrumbClickEventContructor, BreadCrumbPayload, MiniAppEvents } from '../../utils/common';
import { breakpoint } from '../../utils/breakpoints';

interface BreadCrumbBarProps extends SpaContextMenuProps {
    actions: AppActions;
}

export function BreadCrumbBar({ items, activeRoute, baseRoute, navigate, actions }: BreadCrumbBarProps) {
    const { addEventListener, removeEventListener } = actions;
    const [breadCrumbsData, setBreadCrumbs] = useState<BreadCrumbBarProps|null>(null); 
    const { dispatchEvent } = actions;

    if (process.env.NODE_ENV === "development") {
        console.log("BreadCrumbBar rerender :: ", items, activeRoute, baseRoute);
    }

    const eventListener = (eventData: BreadCrumbPayload) => {
        setBreadCrumbs(prevState => {
            return {...prevState, ...eventData};
        });
    }

    useEffect(() => {
        setBreadCrumbs(null);
    }, [activeRoute])

    useEffect(() => {
        addEventListener(MiniAppEvents.BREADCRUMB_UPDATE, eventListener);
        addEventListener(MiniAppEvents.API_USECASE_NAVIGATION, data => {
            sessionStorage.setItem('apiUseCaseNavigation', data);
        });
        return () => {
            removeEventListener(MiniAppEvents.BREADCRUMB_UPDATE, eventListener);
            removeEventListener(MiniAppEvents.API_USECASE_NAVIGATION);
        };
    });

    function onItemClick(e: MouseEvent<HTMLAnchorElement>, uri: string): void {
        if (!!breadCrumbsData) {
            breadCrumbClickEventContructor(dispatchEvent, {
                route: uri
            });
        }

        if (!e.ctrlKey && !e.shiftKey && !e.metaKey) {
            e.preventDefault();
            navigate(uri);
        }
    }

    function activeRouteLabel(): string {
        return safeGet(()=>items.find(f => f.route === activeRoute).label,"")
    }

    function haveCrumbs(): boolean {
        return (breadCrumbsData && Array.isArray(breadCrumbsData.items) && breadCrumbsData.items.length > 0)
    }

    function menu(crumbs) {
        const currentRoutes = () => (haveCrumbs() ? (
            crumbs.items.map((k,i) => (
                k.route ? (
                    <Link
                    key={`crumb-${i}`}
                    onClick={(e) => onItemClick(e, `${baseRoute || ''}${k.route}`)}
                    href={trimLeadingSlash(`${baseRoute || ''}${k.route}`)}
                    >
                        {safeGet(()=>k.label)}
                    </Link>
                ) : (
                    <NoLinksSpan key={i}>{safeGet(()=>k.label)}</NoLinksSpan>
                )
            ))
        ): (
            <NoLinksSpan>{ activeRouteLabel() }</NoLinksSpan>
        ));
        
        return (
            <Breadcrumbs>
            {currentRoutes()}
           </Breadcrumbs>
        )
    }

    return (
        <BreadBarWrap data-testid="toastcrumbs">
            {menu(breadCrumbsData)}
        </BreadBarWrap>
    );
}

const BreadBarWrap = styled.div`
    overflow: hidden;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 100%;
    align-items: center;
    padding: 0 6rem;
    color: ${({ theme }) => theme.color.black} !important;
    height: 2.5rem;
    box-shadow: rgb(0 0 0 / 34%) 0px 2px 7px;
    z-index: 616;
    ${breakpoint.sm`
        padding: 0 2rem;
    `}
`;

const NoLinksSpan = styled.span`
    display: inline-block;
    line-height: 1.5rem;
`;

export default BreadCrumbBar;
