/* eslint-disable import/no-mutable-exports */
/* eslint-disable no-use-before-define */
import { Logger } from '@nab/nab-x-sdk-browser';
import { settings } from '../../logConfig';

export function configureLogger(wrapper: HTMLElement) {
    log = new Logger({ ...settings.dev, wrapper: (wrapper as any).host || wrapper });
}

export let log: Logger;
