import React, { FC, useEffect, useState } from 'react';

import { BackIcon } from '@nab/nui-react';
import { CenteredDiv, StyledBackToTopButton } from '../common/styles';
import { addMultipleEventListeners, removeMultipleEventListeners } from '../../utils/tools';

export const BackToTop: FC = () => {
    const [showTopBtn, setShowTopBtn] = useState(false);
    const appContainer: HTMLElement = document.getElementById('appRoot').querySelector('open-spa-shell');

    const handler = () => {
        if ((appContainer && appContainer.offsetHeight > 900) || window.scrollY > 10) {
            setShowTopBtn(true);
        } else {
            setShowTopBtn(false);
        }
    };

    useEffect(() => {
        addMultipleEventListeners(['wheel', 'resize', 'scroll'], handler);

        return () => {
            removeMultipleEventListeners(['wheel', 'resize', 'scroll'], handler);
        };
    }, []);

    const scrollToTop = () => {
        appContainer.scrollIntoView({
            behavior: 'smooth'
        });
    };

    return (
        <>
            {appContainer && showTopBtn && (
                <CenteredDiv>
                    <StyledBackToTopButton data-testid="backButton" label="Top" icon={BackIcon} variant="primary" onClick={scrollToTop} />
                </CenteredDiv>
            )}
        </>
    );
};
