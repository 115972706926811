// NOTE:
// this stuff will be copied around shells + miniapps
// eventually it might become wiser to pull this out into an external library
// For now i will go with what is easier and copy it around, but if this becomes
// a lot, you should really consider moving it out.
// I wanted to see it work before i externalised it out... soz 🥺🙏

import { DispatchEventAction } from '@nab/x-spa-react';

// events for miniapp < > shell
export enum MiniAppEvents {
    BREADCRUMB_UPDATE = '🆕_🍞',
    BREADCRUMB_ONCLICK_EVENT = '🍞_🖱_🎭',
    FOOTER_UPDATE = 'footer_update',
    UNAUTHORISED_ERROR = 'unauthorised_error',
    API_USECASE_NAVIGATION = 'navigate_to_api_usecase'
}

// Common types
interface MenuNode {
    label: string;
    route: string;
    description?: string;
    icon?: string;
    children?: MenuNode[];
}
export interface BreadCrumbPayload {
    items: MenuNode[];
}

export interface RoutePayload {
    route: string;
}

export const breadCrumbEventContructor = (dispatchEvent: DispatchEventAction, props: BreadCrumbPayload) => {
    dispatchEvent(MiniAppEvents.BREADCRUMB_UPDATE, props);
};

export const breadCrumbClickEventContructor = (dispatchEvent: DispatchEventAction, props: RoutePayload) => {
    dispatchEvent(MiniAppEvents.BREADCRUMB_ONCLICK_EVENT, props);
};
