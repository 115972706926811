import React from 'react';
import PortalSpa from './PortalSpa';
import { MemoryRouter, Route, Switch } from 'react-router';
import { UsageAgreementWall } from './UsageAgreementWall/UsageAgreementWall';
import { NotFoundPage } from './common/ErrorPage/NotFoundPage';

function ShellRoute() {
    return (
        <MemoryRouter>
            <Switch>
                <Route path="/" exact={true} component={UsageAgreementWall} />
                <Route path="/error" exact={true} component={NotFoundPage} />
                <Route path="/portalSpa" exact={true} component={PortalSpa} />
            </Switch>
        </MemoryRouter>
    );
}

export default ShellRoute;
