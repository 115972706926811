import styled from 'styled-components';
import { Panel } from '../common/styles';

const TermsWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    padding: ${({ theme }) => theme.spaces.md} 0 ${({ theme }) => theme.spaces.xxlg};
    margin-top: ${({ theme }) => theme.spaces.md};

    ${Panel} {
        max-width: 700px;
    }
`;

const ActionBar = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    > div:nth-child(1) {
        margin: 0 ${({ theme }) => theme.spaces.md} 0 -0.75rem;

        div:nth-child(2) {
            margin-left: 0.75rem;
            ${() => false /* NOTE: this margin is same padding as label element (nth-child(1) label) */}
        }
        label {
            border: 0;
            text-decoration: underline;
        }
    }
    > div:nth-child(2) {
        margin: ${({ theme }) => theme.spaces.md} 0 0 0;
    }
`;

const LegalWrapper = styled.div`
    overflow-y: scroll;
    border-radius: 4px;

    text-align: left;
    max-height: 38vh;
    &:hover {
        border-color: ${({ theme }) => theme.color.black};
    }
`;

const TermsContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

export { TermsWrapper, ActionBar, LegalWrapper, TermsContainer };
