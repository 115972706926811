import styled from 'styled-components';
import bg from '../../public/images/landing-page-bg.png';
import { breakpoint } from '../../utils/breakpoints';
import { HeaderWrap } from '../Navbar/NavBar.styles';
import {Button} from '@nab/nui-react';

const Panel = styled.article`
    width: 100%;
    text-align: center;
    font-family: ${({ theme }) => theme.font.family.sourcesans};
    max-width: ${({ theme }) => theme.grid.container.maxWidth};
`;

const FlexContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: ${({ theme }) => theme.spaces.md} ${({ theme }) => theme.spaces.md};
`;

const Message = styled.section`
    display: grid;
    grid-template-rows: repeat(3, 1fr);
    grid-gap: ${({ theme }) => theme.spaces.md};
    justify-content: center;
    align-items: center;
    margin-bottom: ${({ theme }) => theme.spaces.lg};
`;

const Links = styled.section`
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(230px, 1fr));
    grid-gap: ${({ theme }) => theme.spaces.md};
    justify-content: center;
    align-items: center;
`;

const Spacer = styled.div`
    margin-bottom: ${({ theme }) => theme.spaces.lg};
`;

const BorderTop = styled.div`
    border-top: 1px ${({ theme }) => theme.color.grey50} solid;
`;

const PageTitle = styled.div`
    padding: 40px 0px 24px 0px;
    text-align: left !important;
`;

const PageDescription = styled.div`
    padding: 0px 0px 24px 0px;
    text-align: left !important;
`;

const FooterContainer = styled.div`
    background-color: ${({ theme }) => theme.color.black};
    padding: 1.5rem 0;
    bottom: 0;
    color: ${({ theme }) => theme.color.white};
    text-align: center;
    > div {
        margin: 3rem 2rem 0 2rem;
    }
`;


const SpaWrapper = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0;
    padding: 0;
    transition: transform 0.3s, -webkit-transform 0.3s;
    &.is-login {
        background: url(${bg}) no-repeat center center fixed;
        background-size: cover;
    }

    @media (max-width: 640px) {
        min-height: 75vh;
    }

    @media (min-width: 640px) {
        min-height: 80vh;
    }

    @media (min-width: 800px) {
        min-height: 85vh;
    }
`;

const MenuAndViewport = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const AppViewport = styled.div`
    width: 100%;
    overflow-y: auto;
    min-height: 84vh;
    flex-direction: column;
    padding: 0;
    display: flex;

    ${breakpoint.sm`
        padding: 0;
    `}
`;

const HideOnMobileElement = styled.div`
    ${breakpoint.sm`
        display: none;
    `}
`;

const MobileWrapper = styled.div`
    ${breakpoint.sm`
    &.has-mobile-open  {
        width: 100%;
        transition: transform .3s,-webkit-transform .3s;
        nav {
            transform: translate3d(0,0,0);
        }
        ${HeaderWrap} {
            min-height: 76px;
        }
        ${SpaWrapper} {
            transform: translate3d(82%,0,0);
            height: 100%;
          
        }
        ${MenuAndViewport}, ${AppViewport} {
            height: 100vh;
            overflow: hidden;
        }
        ${FooterContainer} {
            display: none;
        }
    }
    `}
`;

const StyledBackToTopButton = styled(Button)`
    color: ${({ theme }) => theme.color.white};
    background-color: ${({ theme }) => theme.color.primary};
    text-decoration-line: underline;
    border-radius: ${({ theme }) => theme.spaces.xs};
    min-width: ${({ theme }) => theme.spaces.xs};
    padding: 0.5rem 1rem;
    margin-bottom: 0.2rem;
    div {
        flex-direction: column !important;
        padding-right: 1px;
    }
    svg {
        transform: rotateZ(90deg);
        color: ${({ theme }) => theme.color.white};
    }
`;

const CenteredDiv = styled.div`
    display: flex;
    justify-content: center;
    margin-top: -3.5rem !important;
`;


export {
    SpaWrapper,
    AppViewport,
    MenuAndViewport,
    PageTitle,
    PageDescription,
    Panel,
    FlexContainer,
    Message,
    Links,
    Spacer,
    BorderTop,
    HideOnMobileElement,
    MobileWrapper,
    StyledBackToTopButton,
    CenteredDiv,
    FooterContainer
};
