import React, { useState, useCallback } from 'react';
import { configMgr } from '@nab/nab-x-sdk-browser';

import { ButtonMenu, ButtonIcon, HamburgerIcon, CloseIcon, ChevronDownIcon, ChevronUpIcon, Link } from '@nab/nui-react';
import { AppActions, removeToken, SpaContextMenu, SpaContextMenuProps, useActions } from '@nab/x-spa-react';

// import { MiniAppContext } from '@nab/nab-x-react';
import nabLogo from '../../public/images/nab-logo.svg';
import {
    BannerHeading,
    BrandingWrapper,
    DesktopMenu,
    HeaderWrap,
    LogoContainer,
    MobileOnly,
    UserBlock,
    DropDownContent,
    NameDiv,
    ProfileContainer,
    ProfileDropdown,
    LoginButton,
} from './NavBar.styles';
import { BreadCrumbBar } from '../BreadCrumbBar/BreadCrumbBar';
import { isEmpty } from 'lodash';
import { Banner } from 'components/Banner/Banner';

interface NavProps extends SpaContextMenuProps {
    role: string;
    actions: AppActions;
    setMobileMenuOpen: any;
    mobileMenuOpen: boolean;
    client?: any;
    clientId?: string;
    logoutDafCall?: any;
}

async function Logout() {
    const buildEnvironment = configMgr.get('BUILD_ENVIRONMENT');
    const akamaiPrefix = buildEnvironment === 'local' ? '' : 'openapi/external-developer-shell/latest/';
    await removeToken(sessionStorage.bearerToken); // removes the token from cache

    sessionStorage.clear();
    console.log('configMgr ==> ', configMgr);
    console.log('Logout ==> window.location.origin', window.location.origin);

    window.history.replaceState({}, document.title, `${window.location.origin}/${akamaiPrefix}main/usecases`);
    window.location.reload();
}

export function PortalNavbar(props: NavProps) {
    const { mobileMenuOpen, setMobileMenuOpen, logoutDafCall, activeRoute } = props;
    const [showProfileDropdown, setShowProfileDropdown] = useState(false);
    const { identityInfo = '{}' } = sessionStorage;
    const userInfoObject = JSON.parse(identityInfo);
    const userId = userInfoObject?.username;
    const givenName = userInfoObject?.givenName;
    const username = givenName ? givenName : userId ? userId : 'Username';
    const { navigate } = useActions();

    const onLogin = useCallback(() => {
        const akamaiPrefix = configMgr.get('BUILD_ENVIRONMENT') === 'local' ? '' : 'openapi/external-developer-shell/latest/';
        window.history.replaceState({}, document.title, `${window.location.origin}/${akamaiPrefix}login/identity`);
        window.location.reload();
    }, []);

    const navigateHandler = (url) => {
        navigate(url);
        if (url === '/main/usecases' && activeRoute === '/usecases') {
            window.location.reload();
        }
    };

    return (
        <>
            <HeaderWrap data-testid="header">
                <BrandingWrapper>
                    <MobileOnly data-testid="mmenu">
                        <ButtonIcon
                            data-testid="mobile-menu"
                            label="Menu"
                            isOnDarkBg
                            icon={mobileMenuOpen ? CloseIcon : HamburgerIcon}
                            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
                            color={'primary'}
                        />
                    </MobileOnly>
                    <LogoContainer data-testid="logo" alt="NAB logo" src={nabLogo} />
                    <BannerHeading isOnDarkBg variant="impact">
                        Developer
                    </BannerHeading>
                </BrandingWrapper>

                <DesktopMenu data-testid="dmenu">
                    <SpaContextMenu
                        {...props}
                        items={props.items.filter((menu: any) => !isEmpty(userInfoObject) || !menu.requireLoggedIn)}
                        navigate={navigateHandler}
                        data-testid="desktop-menu"
                    />
                    {!isEmpty(userInfoObject) ? (
                        <UserBlock>
                            <li className="user-menu">
                                <ProfileContainer>
                                    <ProfileDropdown
                                        onClick={() => setShowProfileDropdown(!showProfileDropdown)}
                                        onFocus={() => setShowProfileDropdown(true)}
                                        onBlur={() => setShowProfileDropdown(false)}
                                    >
                                        <NameDiv data-testid="username">{username}</NameDiv>
                                        {showProfileDropdown ? <ChevronUpIcon color="fff" size="sm" /> : <ChevronDownIcon color="fff" size="sm" />}
                                    </ProfileDropdown>
                                </ProfileContainer>
                                {showProfileDropdown && (
                                    <DropDownContent data-testid="mof">
                                        <Link
                                            onClick={async () => {
                                                setShowProfileDropdown(false);
                                                const bearerToken = sessionStorage.getItem('bearerToken');

                                                await logoutDafCall({
                                                    variables: {
                                                        input: {
                                                            action: 'logout',
                                                            bearerToken,
                                                            clientId: props.clientId,
                                                        },
                                                    },
                                                });
                                                await Logout();
                                            }}
                                            className="dropdownButton"
                                        >
                                            Logout
                                        </Link>
                                    </DropDownContent>
                                )}
                            </li>
                        </UserBlock>
                    ) : (
                        <LoginButton variant="primary" label="Login" onClick={onLogin} />
                    )}
                </DesktopMenu>

                <MobileOnly data-testid="mmenu">
                    <ButtonMenu
                        id="mobileLogoutMenu"
                        data-testid="mobile-logout-menu"
                        items={
                            !isEmpty(userInfoObject)
                                ? [{ label: 'Log out', onClick: () => Logout() }]
                                : [{ label: 'Log in', onClick: () => onLogin() }]
                        }
                    />
                </MobileOnly>
            </HeaderWrap>
            <BreadCrumbBar {...props} actions={props.actions as any} />
            {!isEmpty(userInfoObject) && <Banner />}
        </>
    );
}

export default PortalNavbar;
