import React, { useContext, useState } from 'react';
import { Formik, Form } from 'formik';

import { ControlGroup, Container, Checkbox, Button, AuthoriseIcon, Paragraph as P, H1, Row, Col } from '@nab/nui-react';
import LoginNavBar from '../Navbar/LoginNavBar';

import { Panel, Spacer, PageTitle, PageDescription } from '../common/styles';
import { TermsAndConditionsMutationResponse, TermsAndConditionsMutationParams } from '../common/types';
import ACCEPT_TERMS_AND_CONDITIONS from '../../data/mutations/accept-terms-and-conditions';
import GET_TERMS_AND_CONDITIONS from '../../data/queries/get-terms-and-conditions';

import { safeGet } from '../../utils/tools';
import { UserContext } from '../UserContext/UserContext';
import { ActionBar, TermsWrapper, LegalWrapper, TermsContainer } from './UsageAgreementWall.styles';
import { useMutation, useQuery } from 'react-apollo';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { AppLoading } from '../common';

// Note only using router here to change history.
// If you need router higher in shell, feel free to move out of this component.

export function UsageAgreementWall() {
    const history = useHistory();
    const { agreement, setHasAgreedToUsageAgreement, sub } = useContext(UserContext) as any;
    const { loading, error, data } = useQuery(GET_TERMS_AND_CONDITIONS);

    const [isScrollBottom, setIsScrollBottom] = useState(false);

    const CLOSE_ENOUGH_TO_BOTTOM = 20;
    const scrollWarning = 'Please scroll to the end of the terms and conditions';

    const isBot = current =>
        safeGet(() => current.scrollTop, 0) >=
        safeGet(() => current.scrollHeight, 0) - safeGet(() => current.offsetHeight, 10) - CLOSE_ENOUGH_TO_BOTTOM;

    const onScroll = w => {
        setIsScrollBottom(isBot(w.currentTarget));
    };

    const [acceptTermsAndConditions] = useMutation<TermsAndConditionsMutationResponse, TermsAndConditionsMutationParams>(
        ACCEPT_TERMS_AND_CONDITIONS,
        {
            variables: {
                input: {
                    hasUsageAgreement: true,
                    id: data?.termsAndConditions?.id
                }
            }
        }
    );

    const complete = async () => {
        try {
            const { data } = await acceptTermsAndConditions();

            const { hasUsageAgreement } = data.termsAndConditions;
            if (hasUsageAgreement === true) {
                // change local state
                setHasAgreedToUsageAgreement(true);
                window.location.reload();
            }
            history.push('/portalSpa');
        } catch (e) {
            history.push('/error');
        }
    };

    if (loading) {
        return <AppLoading />;
    }

    return (
        <>
            <LoginNavBar role="banner" />
            <div role="main">
                <StyledContainer fluid noSideGutters>
                    <Panel>
                        <PageTitle>
                            <Row>
                                <Col xl="10" lg="10" md="10" sm="12" xs="12">
                                    <H1>Developer use of NAB APIs</H1>
                                </Col>
                            </Row>
                        </PageTitle>
                        <PageDescription>
                            <Row>
                                <Col xl="9" lg="9" md="9" sm="12" xs="12">
                                    <P>
                                        Use of NAB APIs - developer terms.{' '}
                                        {data?.termsAndConditions?.effectiveDate &&
                                            `Effective ${new Date(data?.termsAndConditions?.effectiveDate).toLocaleDateString('en-AU', {
                                                timeZone: 'Australia/Melbourne'
                                            })}.`}
                                    </P>
                                </Col>
                            </Row>
                        </PageDescription>
                    </Panel>
                </StyledContainer>
                <TermsWrapper>
                    <Panel>
                        <Formik
                            initialValues={{
                                agreement: false
                            }}
                            onSubmit={async values => safeGet(() => values.agreement) === true && complete()}
                        >
                            {({ values, setFieldValue, ...rest }) => (
                                <TermsContainer>
                                    <Form>
                                        <LegalWrapper onScroll={onScroll} tabIndex={0}>
                                            <div tabIndex={0}>
                                                <TermsAndConditionsContent content={data?.termsAndConditions?.content} />
                                            </div>
                                        </LegalWrapper>
                                        <Spacer />

                                        <ActionBar>
                                            <ControlGroup
                                                name="agreement"
                                                id="agreement-to-terms"
                                                error={values.agreement && isScrollBottom !== true ? scrollWarning : null}
                                            >
                                                <Checkbox
                                                    data-testid={'checkbox-agreement'}
                                                    name="agreement"
                                                    label="I have read and accept the terms and conditions"
                                                    checked={values.agreement}
                                                    onChange={() => {
                                                        setFieldValue('agreement', !values.agreement);
                                                    }}
                                                />
                                            </ControlGroup>
                                            <div>
                                                <Button
                                                    data-testid={'submit-agreement'}
                                                    disabled={values.agreement !== true || isScrollBottom !== true}
                                                    variant="primary"
                                                    icon={AuthoriseIcon}
                                                    label="Accept"
                                                    type="submit"
                                                />
                                            </div>
                                        </ActionBar>
                                    </Form>
                                </TermsContainer>
                            )}
                        </Formik>
                    </Panel>
                </TermsWrapper>
            </div>
        </>
    );
}

export default UsageAgreementWall;

const StyledContainer = styled(Container)`
    max-width: 700px;
    min-width: 700px;
`;

export const TermsAndConditionsContent = ({ content }) => {
    return <div className="Container" dangerouslySetInnerHTML={{ __html: content }}></div>;
};
