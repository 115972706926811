import styled from 'styled-components';
import { H2 as NH2, Button } from '@nab/nui-react';
import { breakpoint } from '../../utils/breakpoints';

const BannerHeading = styled(NH2)`
    text-transform: uppercase;
`;

const BrandingWrapper = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 0.25rem 2rem.25rem 0;
`;

interface IPosition {
    position?: string;
}
const HeaderWrap = styled.header<IPosition>`
    background-color: ${({ theme }) => theme.color.black};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 100%;
    align-items: center;
    padding: 0 4rem;
    position: ${props => (props.position ? props.position : 'sticky')};
    top: 0;
    z-index: 626;

    ${breakpoint.sm`
        padding: .25rem 1rem;
    `}

    & > a {
        float: left;
        color: ${({ theme }) => theme.color.grey90};
        text-align: center;
        padding: 14px 30px;
        text-decoration: none;
        font-size: 1rem;
        transition: 0.3s;
        cursor: pointer;
    }
    & > a:hover {
        background-color: ${({ theme }) => theme.color.primary};
        border-bottom: 6px solid ${({ theme }) => theme.color.black};
    }
`;

const LogoContainer = styled.img`
    height: 48px;
    margin-right: 15px;
`;

const DesktopMenu = styled.nav`
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    height: 68px;
    align-items: center;

    ${breakpoint.sm`
        display: none;
    `}

    ul {
        color: ${({ theme }) => theme.color.white};
        margin: 0;
        margin-right: auto;
        height: 100%;
        padding: 0;
        display: flex;
        flex-flow: row;
        &:hover {
            &:after {
                border-bottom: 0px !important;
            }
        }

        li.active a,
        li.active .buttonAsLink {
            &:after {
                position: absolute;
                content: '';
                bottom: 0;
                left: 0;
                width: 100%;
                height: 4px;
                border-bottom: 4px solid ${({ theme }) => theme.color.primary};
            }
            &:hover {
                &:after {
                    border-bottom: 4px solid ${({ theme }) => theme.color.primary};
                }
            }
        }

        li a:has(ul.hover) {
            &:after {
                border-bottom: 4px solid ${({ theme }) => theme.color.primary};
            }
        }

        li.active {
            ul {
                display: none;
                background-color: ${({ theme }) => theme.color.white};
                li {
                    height: 60px;
                    margin-top: 0px;
                }
                li a {
                    color: ${({ theme }) => theme.color.black};
                    border-radius: 0px;
                    &:after {
                        position: absolute;
                        content: '';
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        height: 4px;
                        border-bottom: 4px solid ${({ theme }) => theme.color.white};
                    }
                    &:hover {
                        background-color: rgba(38, 50, 56, 0.12);
                        &:after {
                            border-bottom: 4px solid ${({ theme }) => theme.color.black};
                        }
                    }
                }
            }
        }

        li {
            display: block;
            padding: 0;
            align-items: center;
            margin-top: 0.5rem;
            ul {
                display: none;
                background: ${({ theme }) => theme.color.black};
            }
            &:hover {
                ul {
                    display: flex;
                }
            }

            .buttonAsLink {
                width: 100%;
            }

            ul {
                display: none;
                padding: 0px;
                font-weight: bold;
                flex-direction: column;
                background-color: ${({ theme }) => theme.color.white} !important;
                border-radius: 0px;
                box-shadow: rgb(51 51 51) 2px 0px 15px -4px, rgb(51 51 51) -2px 0px 8px -4px;
                width: 10rem;
                height: auto;
                padding: 0 !important;
                li {
                    height: 60px;
                    margin-top: 0px;
                }
                li a {
                    color: ${({ theme }) => theme.color.black};
                    border-radius: 0px;
                    text-align: center;
                    padding: 20px 30px;
                    &:hover {
                        background-color: ${({ theme }) => theme.color.grey90};
                        &:after {
                            border-bottom: 0px !important;
                        }
                    }
                }
            }

            a,
            .buttonAsLink {
                display: block;
                height: 100%;
                color: ${({ theme }) => theme.color.white};
                text-align: left;
                padding: 14px 30px;
                position: relative;
                text-decoration: none;
                font-size: 17px;
                transition: 0.3s;
                cursor: pointer;
                border-radius: 5px;
                white-space: nowrap;
                box-shadow: none;
                outline-style: none;

                &:after {
                    position: absolute;
                    content: '';
                    bottom: 0;
                    left: 0;
                    width: 100%;
                    height: 4px;
                }

                &:hover {
                    &:after {
                        border-bottom: 4px solid ${({ theme }) => theme.color.primary};
                    }
                }
            }
        }
    }
`;

const MobileOnly = styled.div`
    @media (min-width: 756px) {
        display: none;
    }

    svg:hover {
        color: ${({ theme }) => theme.color.white};
    }
    svg:focus {
        color: ${({ theme }) => theme.color.white};
    }
`;

const UserBlock = styled.ul`
    display: flex;
    justify-content: flex-end !important;
    width: 100%;
    li:hover {
        background-color: inherit !important;
    }
    li a {
        &:hover {
            &:after {
                border-bottom: 4px solid ${({ theme }) => theme.color.black} !important;
            }
        }
    }
    #userprofile {
        width: 10rem;
    }
`;

const DropDownContent = styled.div`
    position: fixed;
    background-color: ${({ theme }) => theme.color.white};
    padding: 0px 24px;
    font-weight: bold;
    flex-direction: column;
    margin-top: 7px;
    border-radius: 3px;
    border: 2px solid ${({ theme }) => theme.color.black} !important;
    box-shadow: rgb(51 51 51) 2px 0px 15px -4px, rgb(51 51 51) -2px 0px 8px -4px;
    z-index: 99;
    .dropdownButton {
        color: ${({ theme }) => theme.color.black};
    }
    &:hover {
        background-color: ${({ theme }) => theme.color.black};

        .dropdownButton {
            color: ${({ theme }) => theme.color.white};
            &:after {
                border-bottom: 4px solid ${({ theme }) => theme.color.black};
            }
        }
    }
`;

const NameDiv = styled.span`
    white-space: nowrap;
    margin-right: 0.25rem;
    padding-bottom: 120px;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
    width: max-content;
    text-decoration: underline;
`;

const ProfileContainer = styled.div`
    display: flex;
    align-items: center !important;
    font-weight: 400 !important;
    padding-top: 1px;
    height: 100%;
`;

const ProfileDropdown = styled.a`
    display: inline-block;
    align-items: center;
    svg {
        display: inline-block;
        vertical-align: middle;
    }
    &:hover {
        &:after {
            border-bottom: 4px solid ${({ theme }) => theme.color.black} !important;
        }
    }
`;

const MenuDropdown = styled.span`
    display: block;
    height: 100%;
    color: ${({ theme }) => theme.color.grey90};
    text-align: center;
    padding: 14px 30px;
    position: relative;
    text-decoration: none;
    font-size: 17px;
    transition: 0.3s;
    cursor: pointer;
    border-radius: 5px;
    white-space: nowrap;

    &:after {
        background: ${({ theme }) => theme.color.primary};
        position: absolute;
        content: '';
        bottom: 0;
        left: 0;
        width: 100%;
        height: 4px;
        background: ${({ theme }) => theme.color.black};
    }

    &:hover {
        &:after {
            background: ${({ theme }) => theme.color.white};
        }
    }
`;

const LoginButton = styled(Button)`
    justify-content: flex-end !important;
`;

export {
    MobileOnly,
    DesktopMenu,
    LogoContainer,
    BrandingWrapper,
    HeaderWrap,
    BannerHeading,
    UserBlock,
    DropDownContent,
    NameDiv,
    ProfileContainer,
    ProfileDropdown,
    MenuDropdown,
    LoginButton
};
