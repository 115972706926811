import { map } from "lodash";

export const scrollToTop = () => {
    if (typeof window !== 'undefined') {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
};

export const safeGet = (func: any, fallbackValue: any = null) => {
    try {
        const value = func();
        return value === null || value === undefined ? fallbackValue : value;
    } catch (e) {
        return fallbackValue;
    }
};

export const titleCase = (str: string) => {
    const strLowerCase = str.toLowerCase();
    const wordArr = strLowerCase.split(' ').map(currentValue => {
        return currentValue[0].toUpperCase() + currentValue.substring(1);
    });

    return wordArr.join(' ');
};

export const trimLeadingSlash = (s: string) => {
    return s.replace(/^\//, '');
}

export const addMultipleEventListeners = (eventTypes: string[], callback) => {
    map(eventTypes, type => {
        window.addEventListener(type, callback);
    });
};

export const removeMultipleEventListeners = (eventTypes: string[], callback) => {
    map(eventTypes, type => {
        window.removeEventListener(type, callback);
    });
};