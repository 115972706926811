import React from 'react';
import nabLogo from '../../public/images/nab-logo.svg';
import { BannerHeading, BrandingWrapper, HeaderWrap, LogoContainer } from './NavBar.styles';

export function LoginNavbar(props?) {
    return (
        <>
            <HeaderWrap data-testid="login-header" position={props?.position}>
                <BrandingWrapper>
                    <LogoContainer data-testid="logo" alt="NAB logo" src={nabLogo} />
                    <BannerHeading isOnDarkBg variant="impact">
                        developer
                    </BannerHeading>
                </BrandingWrapper>
            </HeaderWrap>
        </>
    );
}

export default LoginNavbar;
