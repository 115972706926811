import React from 'react';
import { SpaProps, MiniAppInstance } from '@nab/x-spa-react';
import { Theme } from '@nab/nui-react';
import { useMutation, useQuery } from 'react-apollo';
import PortalNavBar from '../../Navbar/PortalNavBar';
import { AppError, AppLoading } from '../../common';
import { safeGet } from '../../../utils/tools';
import GET_USER from '../../../data/queries/get-user';
import USER_LOGOUT from '../../../data/mutations/logout';
import { UserQueryParams, UserQueryResponse } from '../../common/types';
import { UserProvider, UserContext, UserContextInterface } from '../../UserContext/UserContext';
import { AppViewport, MenuAndViewport } from '../../common/styles';
import { ERROR_TYPE } from '../../common/enum';
import ShellRoute from '../../Route';
import { UnauthorizedErrorPage } from '../../common/ErrorPage/UnauthorizedErrorPage';

export interface LoggedInMainAppProps extends Partial<SpaProps> {
    activeRoute: any;
    mobileMenuOpen: boolean;
    setMobileMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
    returnedError401: boolean;
}

const LoggedInMainApp = ({
    activeRoute,
    context,
    activeAppInstance,
    actions,
    appConfig,
    mobileMenuOpen,
    setMobileMenuOpen,
    returnedError401
}: LoggedInMainAppProps) => {
    const { loading, error, data } = useQuery<UserQueryResponse, UserQueryParams>(GET_USER);
    const [logoutDafCall, { loading: userLogoutLoading }] = useMutation<any, any>(USER_LOGOUT);

    if (returnedError401) {
        return <UnauthorizedErrorPage errorType={ERROR_TYPE.ERR_401} />;
    }

    if (loading || userLogoutLoading)
        return (
            <Theme>
                <AppLoading />
            </Theme>
        );

    if (error) {
        const isError401 = JSON.stringify(error?.graphQLErrors[0])?.includes('401') && true;

        if (isError401) {
            return <UnauthorizedErrorPage errorType={ERROR_TYPE.ERR_401} />;
        }
        return <UnauthorizedErrorPage errorType={ERROR_TYPE.ERR_USER} />;
    }

    const { user } = data;
    const userCtx: UserContextInterface = {
        hasUsageAgreement: safeGet(() => user.hasUsageAgreement, false),
        access: safeGet(() => user.access),
        sub: safeGet(() => user.sub)
    };

    return (
        <UserProvider userCtx={userCtx}>
            <UserContext.Consumer>
                {userCtx =>
                    safeGet(() => userCtx.hasUsageAgreement) === true ? (
                        <MenuAndViewport>
                            <PortalNavBar
                                role="banner"
                                activeRoute={activeRoute}
                                items={(context && context.menu) || []}
                                baseRoute={activeAppInstance && appConfig && appConfig.route ? appConfig.route.matchedPath : '/'}
                                navigate={actions && actions.navigate}
                                actions={actions}
                                setMobileMenuOpen={setMobileMenuOpen}
                                mobileMenuOpen={mobileMenuOpen}
                                clientId={safeGet(() => (context as any).appConfig.clientId)}
                                logoutDafCall={logoutDafCall}
                            />

                            <AppViewport role="main">
                                <MiniAppInstance appInstance={activeAppInstance} loadingSlot={AppLoading} errorSlot={AppError} />
                            </AppViewport>
                        </MenuAndViewport>
                    ) : (
                        <ShellRoute />
                    )
                }
            </UserContext.Consumer>
        </UserProvider>
    );
};

export default LoggedInMainApp;
