import React from 'react';
import { SpaProps, MiniAppInstance } from '@nab/x-spa-react';
import PortalNavBar from '../../Navbar/PortalNavBar';
import { AppError, AppLoading } from '../../common';
import { safeGet } from '../../../utils/tools';
import { AppViewport, MenuAndViewport } from '../../common/styles';

export interface AnonymousMainAppProps extends Partial<SpaProps> {
    activeRoute: any;
    mobileMenuOpen: boolean;
    setMobileMenuOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const AnonymousMainApp = ({
    activeRoute,
    context,
    activeAppInstance,
    actions,
    appConfig,
    mobileMenuOpen,
    setMobileMenuOpen
}: AnonymousMainAppProps) => {
    return (
        <MenuAndViewport>
            <PortalNavBar
                role="banner"
                activeRoute={activeRoute}
                items={(context && context.menu) || []}
                baseRoute={activeAppInstance && appConfig && appConfig.route ? appConfig.route.matchedPath : '/'}
                navigate={actions && actions.navigate}
                actions={actions}
                setMobileMenuOpen={setMobileMenuOpen}
                mobileMenuOpen={mobileMenuOpen}
                clientId={safeGet(() => (context as any).appConfig.clientId)}
            />

            <AppViewport role="main">
                <MiniAppInstance appInstance={activeAppInstance} loadingSlot={AppLoading} errorSlot={AppError} />
            </AppViewport>
        </MenuAndViewport>
    );
};

export default AnonymousMainApp;
