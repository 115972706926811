import { AppActions } from '@nab/x-spa-react';
import React, { useEffect, useState } from 'react';
import { MiniAppEvents } from '../../utils/common';
import Footer from './Footer';

interface FooterProps {
    actions?: AppActions;
    activeRoute?: string;
}

export function SpaFooter({ activeRoute, actions }: FooterProps) {
    const { addEventListener, removeEventListener } = actions;
    const [showFooter, setShowFooter] = useState<boolean>(true);

    function eventListener(eventData: boolean) {
        setShowFooter(eventData);
    }

    // clear crumbs on every route change... miniapp need to explicitly pass a payload
    // else shell will contruct crumbs on own
    useEffect(() => {
        setShowFooter(true);
    }, [activeRoute])

    useEffect(() => {
        addEventListener(MiniAppEvents.FOOTER_UPDATE, eventListener);
        return () => {
            removeEventListener(MiniAppEvents.FOOTER_UPDATE, eventListener);
        };
    });

    return (
        showFooter && <Footer />
    );
}