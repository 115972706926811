/* eslint-disable no-console */
import './fonts.css';
import './style.css';
import axios from 'axios';
import {
    ContextConfig,
    DafInMemoryCacheStrategy,
    cacheToken,
    DafExchangeStrategy,
    start,
    registerSpaComponent,
    AuthConfig,
    AuthStrategy,
    DafAuthConfig,
    AuthToken,
    DefaultAuthConfig
} from '@nab/x-spa-react';
import { isEmpty, isObject } from 'lodash';

import LoginSpa from './components/LoginSpa';
import PortalSpa from './components/PortalSpa';
import GlobalSpa from './components/GlobalSpa';
import getContextFromNavManager from './utils/getContextFromNavManager';
import { configMgr } from '@nab/nab-x-sdk-browser';
import { log, configureLogger } from './utils/log/log';
import { safeGet } from './utils/tools';

interface Navigation {
    basePath: string;
    path: string;
}

interface UseCaseNavigation {
    currentNavigation: Navigation;
    nextNavigation: Navigation;
}

export class SeedTokenProviderWrapperStrategy implements AuthStrategy<DafAuthConfig> {
    constructor(protected seedTokenProvider: () => Promise<string>, protected supportedAuthConfig?: DafAuthConfig) {}
    /**
     * The config for the token being requested must match that which is supported by your seedTokenProvider
     */
    public supports(config: DafAuthConfig): boolean {
        if (this.supportedAuthConfig) {
            for (const k of Object.keys(this.supportedAuthConfig)) {
                if (config[k] !== this.supportedAuthConfig[k]) {
                    return false;
                }
            }
        }
        return !!config.isSeed;
    }
    public async authenticate(
        config: DafAuthConfig & { appMetaData: { additionalContexts: any; contextId: string } }
    ): Promise<AuthToken<DafAuthConfig & { appMetaData: { additionalContexts: any; contextId: string } }>> {
        return {
            config: {
                ...config,
                appMetaData: {
                    additionalContexts: undefined,
                    appType: 'XV2SPA',
                    contextId: 'open-global',
                    id: 'SHELL',
                    tag: 'x-root-spa-default'
                }
            },
            tokenValue: sessionStorage.bearerToken
        };
    }
}
/**
 * Retrieve SPA context config
 */

interface ContextConfigurations {
    [key: string]: ContextConfig;
}

let contextConfig: ContextConfigurations;

// eslint-disable-next-line consistent-return
async function spaContextProvider(contextId: string): Promise<ContextConfig> {
    const akamaiPrefix = configMgr.get('BUILD_ENVIRONMENT') === 'local' ? '' : 'openapi/external-developer-shell/latest/';
    try {
        if (!contextConfig) {
            if (contextId === 'root-config') {
                return (await axios.get(`/${akamaiPrefix}config.json`)).data;
            }
            return (
                (contextId === 'root2' && (await getContextFromNavManager(contextId))) ||
                (await axios.get(`/${akamaiPrefix}portal/config/context-${contextId}.json`)).data
            );
        }
    } catch (e) {
        throw new Error(`Failed to retrieve context ${contextId}`);
    }
    try {
        (document.getElementById('favicon') as HTMLLinkElement).href = `${akamaiPrefix}/favicon.ico`;
    } catch (e) {
        log.error(`Failed to set favicon destination`);
    }
}

registerSpaComponent('open-identity', LoginSpa);
registerSpaComponent('open-spa-shell', PortalSpa);

window.addEventListener('load', async () => {
    try {
        configureLogger(document.body);

        // init config mgr
        await configMgr.init();
        const clientGatewayHost = configMgr.get('CLIENT_GW_HOST');

        if (!clientGatewayHost) {
            log.error('D8d01C', `Unable to get CLIENT_GW_HOST variable from config manager`);
            throw new Error(`Unable to get CLIENT_GW_HOST variable from config manager`);
        }

        // Example --> sit1.openapi.api.extnp.national.com.au
        const dafEnvironment = clientGatewayHost.split('.')[0];

        const spaShell = await start({
            // React component which provides the main layout for the shell
            rootComponent: GlobalSpa,

            // Named set of apps and menu items which live in this shell
            rootContextId: 'open-global',

            // Default auth config inherited by all apps which don't explicitly override it
            authConfig: {
                context: 'external', // 'internal' for employee facing shells or 'external' for customer facing shells
                environment: dafEnvironment, // 'prod', 'sit1', 'dev', 'local' etc
                tokenType: 'Bearer',
                tenant: 'openapi',
                tokenSource: 'DAF',
                isSeed: true,
                requestTokenOnLoad: false
            } as DefaultAuthConfig,

            // authStrategies: [new NullAuthStrategy()],
            // Auth strategies applicable to this shell (will most likely include the DafTokenExchangeStrategy)
            authStrategies: [
                new DafExchangeStrategy(`https://${clientGatewayHost}/v1/idp/oauth/token`),
                new SeedTokenProviderWrapperStrategy(() => {
                    return Promise.resolve('');
                })
            ],

            // Defines how tokens are cached & validated
            authTokenCacheStrategy: new DafInMemoryCacheStrategy(),

            // Defines how context config is retrieved (eg. from an API, JSON file on disk etc...)
            spaContextProvider,

            userConfig: {
                org: 'NAB',
                type: 'customer'
            },

            hostElement: document.querySelector('#appRoot')
        });

        spaShell.config.actions.addEventListener('app_start', async () => {
            if (sessionStorage.bearerToken) {
                await cacheToken({
                    tokenValue: sessionStorage.bearerToken,
                    config: {
                        tokenType: 'Bearer',
                        tokenSource: 'DAF',
                        tenant: 'openapi',
                        context: 'external',
                        environment: dafEnvironment,
                        isSeed: true
                    } as DefaultAuthConfig
                });
            }
        });
        spaShell.config.actions.addEventListener('user_authenticated', async data => {
            const config: AuthConfig = {
                tokenType: 'Bearer',
                tokenSource: 'DAF',
                tenant: 'openapi',
                context: 'external',
                environment: dafEnvironment,
                isSeed: true
            } as DefaultAuthConfig;

            if (process.env.NODE_ENV === 'development') {
                console.log('🤌 user_authenticated', data && data.bearerToken);
            }

            if (data) {
                await cacheToken({
                    tokenValue: data && data.bearerToken,
                    config
                });

                // Redirect login to api if User comes from use case journey api select(open api miniapp)
                const useCaseNavigationString = sessionStorage.getItem('apiUseCaseNavigation');
                const useCaseNavigation = safeGet(() => JSON.parse(useCaseNavigationString)) as UseCaseNavigation;

                if (isObject(useCaseNavigation) && !isEmpty(useCaseNavigation)) {
                    const { currentNavigation } = useCaseNavigation;
                    const { basePath, path } = currentNavigation;
                    await spaShell.config.actions.navigate(`/main/${basePath}/${path}`);
                } else {
                    // If not then go to the landing page
                    await spaShell.config.actions.navigate('/main');
                }
            } else if (sessionStorage.bearerToken) {
                await cacheToken({
                    tokenValue: sessionStorage.bearerToken,
                    config
                });
                await spaShell.config.actions.navigate('/main');
            }
        });
        spaShell.config.actions.addEventListener('user_timeout', async () => {
            window.history.replaceState({}, document.title, window.location.origin);
            sessionStorage.clear();
            window.location.reload();
        });
        if (process.env.NODE_ENV === 'development') {
            console.log('SPA started successfully');
        }
    } catch (e) {
        if (process.env.NODE_ENV === 'development') {
            console.error('SPA failed to start', e);
        }
    }
});
