import React from 'react';
import { Theme, Container, Link, BackIcon } from '@nab/nui-react';
import { ERROR_TYPE } from '../enum';
import LoginNavBar from '../../Navbar/LoginNavBar';
import { ErrorComponent, getOrigin } from './ErrorHelper';
import { configMgr } from '@nab/nab-x-sdk-browser';

export function NotFoundPage() {
    // deepcode ignore DeleteOfNonProperty: False positive, this is to delete bearerToken if exists
    delete sessionStorage?.bearerToken;

    const akamaiPrefix = configMgr.get('BUILD_ENVIRONMENT') === 'local' ? '' : 'openapi/external-developer-shell/latest/';

    return (
        <>
            <div style={{ position: 'fixed', top: '0', width: '-webkit-fill-available', left: '0' }}>
                <LoginNavBar role="banner" position={'initial'} />
                <div style={{ paddingLeft: '24px', paddingTop: '16px' }}>
                    <Link href={`${getOrigin()}/${akamaiPrefix}login/identity`} icon={BackIcon} color="primary">
                        Login Page
                    </Link>
                </div>
            </div>
            <Container fluid noSideGutters>
                <Theme>
                    <ErrorComponent errorType={ERROR_TYPE.ERR_USER} />
                </Theme>
            </Container>
        </>
    );
}
