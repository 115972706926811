import React from 'react';
import { ERROR_TYPE, ERROR_MESSAGE } from '../enum';
import { NotFound } from './NotFound';
import { configMgr } from '@nab/nab-x-sdk-browser';

export interface IErrorType {
    errorType: ERROR_TYPE.ERR_401 | ERROR_TYPE.ERR_USER;
}

/**
 * Function will return Error Component based on the error type
 * @param errorType : IErrorType
 * @returns Error Component
 */
export function ErrorComponent({ errorType }: IErrorType) {
    let heading = '';
    let subhead = ERROR_MESSAGE.DEFAULT;

    switch (errorType) {
        case ERROR_TYPE.ERR_401:
            heading = ERROR_MESSAGE.UNAUTHORIZED;
            subhead = ERROR_MESSAGE.UNAUTHORIZED_SUBHEADING;
            break;

        case ERROR_TYPE.ERR_USER:
            heading = ERROR_MESSAGE.RETRIEVING_USER_FAIL;
            break;
    }

    return <NotFound heading={heading} subhead={subhead} errorType={errorType}></NotFound>;
}

export function getOrigin() {
    const clientGatewayHost = configMgr.get('CLIENT_GW_HOST');

    switch (true) {
        case clientGatewayHost.includes('sit5'):
            return 'https://developer.extnp.nab.com.au';
        case clientGatewayHost.includes('sit1'):
            return 'https://portal-developer.extnp.nab.com.au';
        case clientGatewayHost.includes('localhost'):
            return 'https://localhost:9080';
        case clientGatewayHost === 'open.api.nab.com.au':
        case clientGatewayHost === 'https://open.api.nab.com.au':
        default:
            return 'https://portal-developer.nab.com.au';
    }
}
