import React from 'react';

export function RouteError({ routeError }) {
    return (
        <div>
            <h2>404</h2>
            <p>Route not found: {routeError.uri}</p>
        </div>
    );
}

export default RouteError;
