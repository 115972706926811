import { gql } from 'apollo-boost';

export default gql`
    query GetTermsAndConditions {
        termsAndConditions {
            version
            effectiveDate
            content
            hasUsageAgreement
            id
        }
    }
`;
