import { gql } from 'apollo-boost';

export default gql`
    query GetUser {
      user {
          access
          sub
          hasUsageAgreement
          createdAt
          deletedAt
      }
    }
`;
