import React, { useMemo } from 'react';
import { SpaProps, MiniAppInstance } from '@nab/x-spa-react';
import styled from 'styled-components';
import { GlobalStyle, Theme } from '@nab/nui-react';

import LoginNavBar from './Navbar/LoginNavBar';
import { AppError, AppLoading } from './common';
import { AppViewport, SpaWrapper } from './common/styles';
import { breakpoint } from '../utils/breakpoints';
import Footer from './Footer/Footer';

const LoginWrapper = styled.div`
    max-width: 500px;
    margin: 4rem;
    min-width: 500px;

    ${breakpoint.sm`
        max-width: none;
        width: 100%;
        margin: 2rem 0rem;
    `}
`;

const LoginMiniAppContainer = styled.div`
    padding: 2.5rem;
    border-radius: 5px 5px 0 0;
    background: ${({ theme }) => theme.color.white};

    ${breakpoint.sm`
        width: 100%;
    `}
`;

const LoginToolbar = styled.div`
    padding: 1.5rem 2.5rem;
    border-radius: 0 0 5px 5px;
    background: #e6e6e6;
`;

export default function LoginSpa({ context, activeAppInstance, initError, routeError, actions, appConfig }: SpaProps) {
    return (
        <>
            <GlobalStyle normalize borderBox />
            <Theme>
                <SpaWrapper>
                    <LoginNavBar role="banner" />
                    {useMemo(
                        () => (
                            <AppViewport role="main">
                                <LoginWrapper>
                                    <LoginMiniAppContainer>
                                        <MiniAppInstance appInstance={activeAppInstance} loadingSlot={AppLoading} errorSlot={AppError} />
                                    </LoginMiniAppContainer>
                                    <LoginToolbar>
                                        For support e-mail <a href="mailto:nabapi@nab.com.au">nabapi@nab.com.au</a>
                                    </LoginToolbar>
                                </LoginWrapper>
                            </AppViewport>
                        ),
                        [activeAppInstance]
                    )}
                </SpaWrapper>
                <Footer />
            </Theme>
        </>
    );
}
