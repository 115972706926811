import React, { useMemo, useState, useEffect } from 'react';
import { SpaProps } from '@nab/x-spa-react';
import { useActions } from '@nab/nab-x-react';
import { GlobalStyle, Theme } from '@nab/nui-react';
import { ApolloProvider } from 'react-apollo';
import MobileMenu from './Navbar/MobileMenu';

import { safeGet } from '../utils/tools';
import { getApolloClient } from '../utils/graphql/ApolloClient';

import { SpaWrapper, MobileWrapper } from './common/styles';
import { SpaFooter } from './Footer/SpaFooter';
import { MiniAppEvents } from '../utils/common';
import { AnonymousMainApp, LoggedInMainApp } from './MainApp';

const PortalSpa = ({ context, activeAppInstance, actions, appConfig }: SpaProps) => {
    if (process.env.NODE_ENV === 'development') {
        console.log('PortalSpa rerender :: ', context, activeAppInstance, appConfig);
    }
    const fullRoute = window.location.pathname;
    const appPath = safeGet(() => appConfig.route.ownPath);
    const activeRoute = safeGet(() => fullRoute.split(appPath)[1]);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
    const [returnedError401, setReturnedError401] = useState(false);
    const [tokenConfig, setTokenConfig] = useState(null);
    const { authenticate, addEventListener, removeEventListener } = useActions();

    function eventListener(eventData: boolean) {
        setReturnedError401(eventData);
    }

    useEffect(() => {
        setReturnedError401(false);
    }, [activeRoute]);

    useEffect(() => {
        addEventListener(MiniAppEvents.UNAUTHORISED_ERROR, eventListener);
        return () => {
            removeEventListener(MiniAppEvents.UNAUTHORISED_ERROR, eventListener);
        };
    });

    useEffect(() => {
        (async () => {
            try {
                setTokenConfig(await authenticate());
            } catch (e) {
                console.log('Error: Unable to get token');
            }
        })();
    }, [authenticate]);

    const client = useMemo(() => {
        if (tokenConfig) {
            return getApolloClient(tokenConfig && tokenConfig['tokenValue']);
        }
    }, [tokenConfig]);

    if (!client) {
        return <>loading</>;
    }

    const MemoMobileMenu = () => (
        <>
            {useMemo(
                () => (
                    <MobileMenu
                        activeRoute={activeRoute}
                        items={(context && context.menu) || []}
                        baseRoute={activeAppInstance && appConfig && appConfig.route ? appConfig.route.matchedPath : '/'}
                        navigate={actions && actions.navigate}
                        actions={actions}
                    />
                ),
                [],
            )}
        </>
    );

    const MemoFooter = () => (
        <>
            {useMemo(
                () => (
                    <SpaFooter activeRoute={activeRoute} actions={actions} />
                ),
                [],
            )}
        </>
    );

    const MemoMainApp = () => (
        <>
            {useMemo(
                () => (
                    <>
                        {/* User is logged in */}
                        {tokenConfig && tokenConfig['tokenValue'] ? (
                            <ApolloProvider client={client}>
                                <LoggedInMainApp
                                    activeRoute={activeRoute}
                                    context={context}
                                    activeAppInstance={activeAppInstance}
                                    actions={actions}
                                    appConfig={appConfig}
                                    setMobileMenuOpen={setMobileMenuOpen}
                                    mobileMenuOpen={mobileMenuOpen}
                                    returnedError401={returnedError401}
                                />
                            </ApolloProvider>
                        ) : (
                            <>
                                {/* User is anonymous */}
                                <AnonymousMainApp
                                    activeRoute={activeRoute}
                                    context={context}
                                    activeAppInstance={activeAppInstance}
                                    actions={actions}
                                    appConfig={appConfig}
                                    setMobileMenuOpen={setMobileMenuOpen}
                                    mobileMenuOpen={mobileMenuOpen}
                                />
                            </>
                        )}
                    </>
                ),
                [],
            )}
        </>
    );

    return (
        <>
            <GlobalStyle normalize borderBox />
            <Theme>
                <MobileWrapper className={mobileMenuOpen ? 'has-mobile-open' : ''}>
                    <MemoMobileMenu />
                    <SpaWrapper>
                        <MemoMainApp />
                    </SpaWrapper>
                    <MemoFooter />
                </MobileWrapper>
            </Theme>
        </>
    );
};

export default PortalSpa;
