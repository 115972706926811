import { gql } from 'apollo-boost';

export default gql`
    query GetBannerDetails {
      bannerDetails {
        enabled
        title
        content
      }
    }
`;
