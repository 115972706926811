import React from 'react';
import { Spinner } from '@nab/nui-react';
import { LoadingSlotProps } from '@nab/x-spa-react/module/components/MiniAppInstance';
import styled from 'styled-components';

const OSpinner = styled(Spinner)`
    & > div > div {
        border-bottom: 0.3125rem solid #c00;
        border-left: 0.3125rem solid #c00;
    }
`;

const AppLoading: React.FC<Partial<LoadingSlotProps>> = ({ app }) => {
    return (
        <div
            className="loadingScreen"
            style={{
                position: 'absolute',
                left: '50%',
                top: '50%',
                transform: 'translate(-50%, -50%)'
            }}
        >
            <OSpinner data-testid="Spinner" size="lg" />
        </div>
    );
};

export default AppLoading;
