import React from 'react';
import { Link, ChevronRightIcon } from '@nab/nui-react';
import { AppActions, SpaContextMenuProps } from '@nab/x-spa-react';

import styled from 'styled-components';
import { isEmpty } from 'lodash';
const MobileNav = styled.nav`
    background: #323537;
    width: 82%;
    height: 100%;
    position: absolute;
    left: 0;
    z-index: 👹;
    z-index: 666;
    transition: transform 0.3s, -webkit-transform 0.3s;
    transform: translate3d(-100%, 0, 0);
    ul {
        padding: 0;
        list-style: none;
    }
    li {
        border-bottom: 1px solid #6b7682;
        &:first-of-type {
            border-top: 1px solid #6b7682;
        }
    }
    li a {
        padding: 1rem 1rem 1rem 1.5rem;
        width: 100%;
        justify-content: space-between;
        transition: all 0.1s ease-in;

        span {
            order: 1;
        }
        &:hover {
            padding-left: 2rem;
            color: ${({ theme }) => theme.color.white};
            background: #292c2e;
            svg {
                color: ${({ theme }) => theme.color.primary};
            }
        }
    }
`;

interface NavProps extends SpaContextMenuProps {
    actions: AppActions;
}

export function MobileMenu(props: NavProps) {
    const { items, activeRoute, baseRoute } = props;

    const { identityInfo = '{}' } = sessionStorage;
    const userInfoObject = JSON.parse(identityInfo);
    const filteredItems = items.filter((menu: any) => !isEmpty(userInfoObject) || !menu.requireLoggedIn);

    function trimLeadingSlash(s: string) {
        return s.replace(/^\//, '');
    }

    function isRouteActive(route: string): boolean {
        return activeRoute && !!activeRoute.match(new RegExp(`^${route}(/.*)?$`)); // activeRoute.indexOf(route) === 0;
    }

    return (
        <MobileNav aria-label="Mobile menu">
            <ul>
                {filteredItems.map(item => (
                    <li key={`${item.icon}${item.label}${item.route}`} className={isRouteActive(item.route) ? 'active' : ''}>
                        <Link
                            aria-describedby={item.description ? item.description : ''}
                            href={trimLeadingSlash(`${baseRoute || ''}${item.route}`)}
                            icon={ChevronRightIcon}
                            isOnDarkBg
                        >
                            {item.label}
                        </Link>
                    </li>
                ))}
            </ul>
        </MobileNav>
    );
}

export default MobileMenu;
