import React from 'react';
import { WarningOutlineIcon, H1, H5 } from '@nab/nui-react';
import styled from 'styled-components';
import { breakpoint } from 'utils/breakpoints';
import { useQuery } from 'react-apollo';
import GET_BANNER_DETAILS from '../../data/queries/get-banner-details';
import { AppLoading } from '../common';

interface IPosition {
    position?: string;
}

const BannerWrap = styled.div<IPosition>`
    background-color: ${({ theme }) => theme.color.primary};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: nowrap;
    width: 100%;
    align-items: center;
    padding: 1rem 4rem;
    position: ${(props) => (props.position ? props.position : 'sticky')};
    top: 0;

    ${breakpoint.sm`
        padding: .25rem 1rem;
    `}

    & > a {
        float: left;
        color: ${({ theme }) => theme.color.grey90};
        text-align: center;
        padding: 14px 30px;
        text-decoration: none;
        font-size: 1rem;
        transition: 0.3s;
        cursor: pointer;
    }
    & > a:hover {
        background-color: ${({ theme }) => theme.color.primary};
        border-bottom: 6px solid ${({ theme }) => theme.color.black};
    }
`;

export const Banner = () => {
    const { loading, data } = useQuery(GET_BANNER_DETAILS);

    if (loading) {
        return <AppLoading />;
    }

    const { bannerDetails } = data;
    const { enabled: isEnable, title, content } = bannerDetails;

    if (isEnable) {
        return (
            <BannerWrap data-testid="banner">
                <div style={{ display: 'flex' }}>
                    <div
                        style={{
                            marginRight: '1rem',
                            alignSelf: 'center',
                        }}
                    >
                        <WarningOutlineIcon color="white" size="xxxlg" />
                    </div>
                    <div>
                        <H1 variant="impact" isOnDarkBg>
                            {title}
                        </H1>
                        <H5 isOnDarkBg variant="semibold">
                            {content}
                        </H5>
                    </div>
                </div>
            </BannerWrap>
        );
    }
};
